<template>

    <div id="tools-page" class="container-fluid">

        <h2>Searchable catalogue of patient derived models<br> <span class="small">(last updated November 6th,
                2024)</span></h2>

        <div id="tools-table" class="row">
            <div class="col-xs-2 col-sm-2 col-md-3 col-lg-3">
                <b-form-input class="menu-wrapper" id="searchTable" v-model="searchText"
                    placeholder="ex. Samples from table"></b-form-input>


                <div class="dropdown-menu-wrapper">
                    <div v-b-toggle.collapse-columnMenu class="dropdown-menu-button"
                        v-on:click="toggleCarrot('collapse-columnMenu')">
                        <span class="dropdown-menu-label">Hide/show columns</span>
                        <span class="dropdown-menu-carrot" id="collapse-columnMenu-carrot">&#9660;</span>
                    </div>

                    <b-collapse id="collapse-columnMenu" class="dropdown-menu-options">
                        <b-form-checkbox v-for="(each, index) in columnToggleOptions" :key="each.key"
                            class="custom-control custom-checkbox b-custom-control-sm" :id="each.key"
                            @change="onColumnToggle(index)" checked="true">
                            {{ columnNameMap[each.key].name }}
                        </b-form-checkbox>
                    </b-collapse>
                </div>

                <div id="filter-panel-wrapper">

                    <div v-for="each in filter.options" v-bind:key="each.key" class="dropdown-menu-wrapper">
                        <div class="dropdown-menu-button" v-b-toggle="[`collapse-${each.key}`]"
                            v-on:click="toggleCarrot(`collapse-${each.key}`)">
                            <span class="dropdown-menu-label">{{ columnNameMap[each.key].name }}</span>
                            <span>
                                <span class="dropdown-menu-value">{{ each.values.length }} types</span>
                                <span class="dropdown-menu-carrot"
                                    :id="'collapse-' + each.key + '-carrot'">&#9650;</span>
                            </span>
                        </div>

                        <b-collapse :id="'collapse-' + each.key" class="dropdown-menu-options" visible>
                            <b-form-checkbox v-for="value in each.values" v-bind:key="value.key"
                                @change="onCheckFilter(value.key, value.property)" :value="value.key"
                                :id="value.property + '-' + value.key + '-checkbox'" size="sm">
                                {{ value.key }}<span class="checkbox-value"> {{ value.values.length }}</span>
                            </b-form-checkbox>
                        </b-collapse>

                    </div>
                </div>

            </div>
            <div id="dataTable-wrapper" class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <table id="tableId" class="display" style="width:100%"></table>
            </div>

        </div>
    </div>
</template>

<script>
// http://live.datatables.net/xehimatu/1/edit
// https://datatables.net/extensions/searchpanes/examples/customFiltering/customPane.html

// https://medium.com/better-programming/creating-a-multi-filter-function-to-filter-out-multiple-attributes-javascript-react-rails-5aad8e272142
// https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72

import * as d3 from "d3";
import { nest } from 'd3-collection';
import { map } from 'd3-collection';
//import Multiselect from 'vue-multiselect'

let tableId = "tableId"
let table1DataFile = 'data/CCLF_Verified_Models_20241118.tsv'; // path needs to be based from index.html, and file goes in public folder
let table2DataFile = 'data/CCLF_media_recipes_20230306.csv';


export default {
    //    props: {
    // template parameter
    //   },
    // components: {
    //     Multiselect
    // },
    data() {
        const columnNameMap = { // this is in same order as Table Columns, used to make columnToggle
            internalCCLFId: { name: "Internal CCLF ID" },
            patientSampleSource: { name: "Patient sample source" },
            DepmapModelId: { name: "DepMap Model ID, if applicable" },
            CCLFPublicationId: { name: "CCLF Publication ID, if applicable" },
            organSystem: { name: "Organ System" },
            lineage: { name: "Lineage" },
            diagnosis: { name: "Diagnosis" },
            diagnosisSubtype: { name: "Diagnosis Subtype" },
            rareOrCommon: { name: "Rare or Common Cancer Model?" },
            verificationMethod: { name: "Verification method" },
            termCulture: { name: "Short term or Long term culture?" },
            cultureSystem: { name: "Culture system" },
            cultureMedia: { name: "Culture media" },
            passExpansion: { name: "Pass expansion?" },
            statusInModelDerivationPipeline: { name: "Status in model derivation pipeline" },
            flaskConditions: { name: "Flask Conditions" },
            patientOrPDXTumorTissue: { name: "Patient tumor tissue or PDX tumor tissue?" },
            HCMIModel: { name: "HCMI model?" },
            depositATCC: { name: "Deposited at ATCC?" },
            accesibility: { name: "Accessibility" },
            patientTumorType: { name: "Patient tumor type" },
            gender: { name: "Gender" },
            ageAtCollection: { name: "Age at Collection" },
            sampleCollectionSite: { name: "Sample Collection Site" },
            raceOrEthnicity: { name: "Race or Ethnicity" },
            treatmentHistory: { name: "Treatment History" },
            WESdata: { name: "WGS/WES data available at Broad?" },
            RNAseqData: { name: "RNAseq data available at Broad?" },
            statusInDepMap: { name: "Handed off to DepMap?" },
            quarterModelAdded: { name: "Quarter model added to this list" }
        }

        return {
            data: [],
            searchText: "",
            columnNameMap,
            columnToggleOptions: Object.keys(columnNameMap).map(function (d) {
                return {
                    key: d,
                    label: columnNameMap[d].name
                }
            }),
            filter: {
                options: [],
                selected: [],
                columns: ["rareOrCommon", "lineage", "diagnosis", "diagnosisSubtype"]
            }
        };
    },
    mounted() {
        this.init();
    },
    beforeUpdate() {
        this.addTblValToltip();
    },
    methods: {
        init() {
            Promise.all([
                d3.tsv(table1DataFile, function (d) {
                    return {
                        internalCCLFId: d["Internal CCLF ID"],
                        patientSampleSource: d["Patient sample source"],
                        DepmapModelId: d["DepMap Model ID, if applicable"],
                        CCLFPublicationId: d["CCLF Publication ID, if applicable"],
                        organSystem: d["Organ System"],
                        lineage: d["Lineage"],
                        diagnosis: d["Diagnosis"],
                        diagnosisSubtype: d["Diagnosis Subtype"],
                        rareOrCommon: d["Rare or Common Cancer Model?"],
                        verificationMethod: d["Verification method"],
                        termCulture: d["Short term or Long term culture?"],
                        cultureSystem: d["Culture system"],
                        cultureMedia: d["Culture media"].split(", "),
                        passExpansion: d["Pass Expansion?"],
                        statusInModelDerivationPipeline: d["Status in model derivation pipeline"],
                        flaskConditions: d["Flask Conditions"],
                        patientOrPDXTumorTissue: d["Patient tumor tissue or PDX tumor tissue?"],
                        HCMIModel: d["HCMI model?"],
                        depositATCC: d["Deposited at ATCC?"],
                        accesibility: d["Accessibility"],
                        patientTumorType: d["Patient tumor type"],
                        sampleCollectionSite: d["Sample Collection Site"],
                        gender: d["Gender"],
                        ageAtCollection: d["Age at Collection"],
                        raceOrEthnicity: d["Race or Ethnicity"],
                        treatmentHistory: d["Treatment History"],
                        WESdata: d["WGS/WES data available at Broad?"],
                        RNAseqData: d["RNAseq data available at Broad?"],
                        statusInDepMap: d["Handed off to DepMap?"],
                        quarterModelAdded: d["Quarter model added to this list"]
                    }
                }),
                d3.csv(table2DataFile, function (d) {
                    return {
                        SOPNumber: d["SOP Number"],
                        SOPName: d["SOP Name"],
                        date: d["date"],
                        version: d["version"],
                        fileName: d["PDF"]
                    }
                }),

            ]).then((data) => {

                let sopNameToFile = map();
                data[1].forEach(function (d) { sopNameToFile.set(d.SOPName, d.fileName) })
                // console.log(data)
                this.verifiedModels = data[0]

                this.filter.options = createFilterOptions(data[0], this.filter.columns) // rename createFilterOptions
                this.filter.selections = createFilterSelections(data[0], this.filter.columns)
                let columnNameMap = this.columnNameMap;
                // let columns = Object.keys(this.columnNameMap);
                // this.columns = columns.map(function(d){
                //     return {
                //         key: d,
                //         label: columnNameMap[d].name
                //     }
                // })
                $(`#${tableId}`).DataTable({
                    destroy: true,
                    paging: true,
                    // fixedHeader: true,
                    scrollX: true,
                    scrollResize: true,
                    //  dom: '<"top"i>t<"bottom"lp><"clear">',
                    dom: 'iBtip',
                    buttons: [
                        'csv', 'excel'
                    ],
                    order: [[0, 'asc']], // sorting by the input order (the first column specified)
                    fixedColumns: true,
                    autoWidth: false,
                    columnDefs: [
                        { width: "190px", targets: "_all" } // Sets all columns to 100px width
                    ],
                    data: this.verifiedModels,
                    columns: [
                        {
                            title: `${columnNameMap["internalCCLFId"].name}`,
                            data: 'internalCCLFId'
                        },
                        {
                            title: `${columnNameMap["patientSampleSource"].name}`,
                            data: 'patientSampleSource'
                        },
                        {
                            title: `${columnNameMap["DepmapModelId"].name} <span class="info-circle" data-toggle="tooltip" title="The ID registered with the Cancer Dependency Map">&#9432;</span>`,
                            data: 'DepmapModelId'
                        },
                        {
                            title: `${columnNameMap["CCLFPublicationId"].name}`,
                            data: 'CCLFPublicationId'
                        },
                        {
                            title: `${columnNameMap["organSystem"].name}`,
                            data: 'organSystem'
                        },

                        {
                            title: `${columnNameMap["lineage"].name}`,
                            data: 'lineage'
                        },

                        { title: `${columnNameMap["diagnosis"].name}`, data: 'diagnosis' },

                        { title: `${columnNameMap["diagnosisSubtype"].name}`, data: 'diagnosisSubtype' },

                        { title: `${columnNameMap["rareOrCommon"].name}`, data: 'rareOrCommon' },

                        { title: `${columnNameMap["verificationMethod"].name} <span class="info-circle" data-toggle="tooltip" title="The method used to confirm a cell line as tumor">&#9432;</span>`, data: 'verificationMethod' },

                        { title: `${columnNameMap["termCulture"].name} <span class="info-circle" data-toggle="tooltip" title="Short term passage = ~p3-p5; Long term passage = beyond passage 5 and higher, through the end of Expansion">&#9432;</span>`, data: 'termCulture' },

                        { title: `${columnNameMap["cultureSystem"].name} <span class="info-circle" data-toggle="tooltip" title="2D adherent; 2D suspension; 2D mix (mix of adherent and suspension cells); 3D organoid cultured in matrigel">&#9432;</span>`, data: 'cultureSystem' },
                        {
                            title: `${columnNameMap["flaskConditions"].name} <span class="info-circle" data-toggle="tooltip" title="What were the flask conditions?">&#9432;</span>`,
                            data: 'flaskConditions'
                        },
                        {
                            title: `${columnNameMap["cultureMedia"].name}`,
                            data: 'cultureMedia',
                            render: function (d) {
                                let returned = []
                                for (var i = 0; i < d.length; i++) {
                                    if (sopNameToFile.get(d[i]) == undefined || sopNameToFile.get(d[i]) == "") {
                                        returned.push(`<span >${d[i]}</span>`)
                                    } else {
                                        returned.push(`<a href="#" onclick="window.open('sop_pdfs/${sopNameToFile.get(d[i])}.pdf', '_blank', 'fullscreen=yes'); return false;"> ${d[i]}</a>`)
                                    }
                                }
                                return `<span> ${returned} </span>`

                            }
                        },
                        {
                            title: `${columnNameMap["passExpansion"].name} <span class="info-circle" data-toggle="tooltip" title="After the cell line was verifed as tumor, did it successfully reach the end of Expansion? (Yes; No; In Progress; No attempt yet; On Hold)">&#9432;</span>`,
                            data: 'passExpansion'
                        },

                        {
                            title: `${columnNameMap["statusInModelDerivationPipeline"].name} <span class="info-circle" data-toggle="tooltip" title="Stages in the CCLF pipeline: Nursery, Nursery Complete, Expansion, Expansion Complete, On Hold, Terminated">&#9432;</span>`,
                            data: 'statusInModelDerivationPipeline'
                        },

                        {
                            title: `${columnNameMap["patientOrPDXTumorTissue"].name}`,
                            data: 'patientOrPDXTumorTissue'
                        },

                        {
                            title: `${columnNameMap["HCMIModel"].name} <span class="info-circle" data-toggle="tooltip" title="Is this cancer model a part of the Human Cancer Models Initiative? (Yes; No; TBD = model derivation still in progress; pending = awaiting documentation; N/A = model failed expansion)">&#9432;</span>`,
                            data: 'HCMIModel'
                        },

                        {
                            title: `${columnNameMap["depositATCC"].name} <span class="info-circle" data-toggle="tooltip" title="Has this cancer model been submitted to ATCC? (Yes; To be shipped; Pending, N/A)">&#9432;</span>`,
                            data: 'depositATCC'
                        },

                        {
                            title: `${columnNameMap["accesibility"].name} <span class="info-circle" data-toggle="tooltip" title="Where this cell line is currently accessible: (1) Available at ATCC; (2) Deposited at ATCC; not yet available online; (3) Currently at CCLF">&#9432;</span>`,
                            data: 'accesibility',
                            render: function (d) {
                                if (d == "Available at ATCC") {
                                    return `<a href="https://www.atcc.org/search#q=brod&sort=relevancy" target="_blank">${d}</a>`
                                } else {
                                    return d
                                }
                            }
                        },
                        {
                            title: `${columnNameMap["patientTumorType"].name} <span class="info-circle" data-toggle="tooltip" title="Primary; Secondary; Metastatic; Recurrent; Unknown">&#9432;</span>`,
                            data: 'patientTumorType'
                        },
                        {
                            title: `${columnNameMap["sampleCollectionSite"].name}`,
                            data: 'sampleCollectionSite'
                        },

                        {
                            title: `${columnNameMap["gender"].name}`,
                            data: 'gender'
                        },

                        {
                            title: `${columnNameMap["ageAtCollection"].name}`,
                            data: 'ageAtCollection'
                        },

                        {
                            title: `${columnNameMap["raceOrEthnicity"].name}`,
                            data: 'raceOrEthnicity'
                        },

                        {
                            title: `${columnNameMap["treatmentHistory"].name} <span class="info-circle" data-toggle="tooltip" title="Patient treatment history at the time of sample collection">&#9432;</span>`,
                            data: 'treatmentHistory'
                        },

                        {
                            title: `${columnNameMap["WESdata"].name}`,
                            data: 'WESdata'
                        },
                        {
                            title: `${columnNameMap["RNAseqData"].name}`,
                            data: 'RNAseqData'
                        },

                        {
                            title: `${columnNameMap["statusInDepMap"].name}`,
                            data: 'statusInDepMap'
                        },
                        {
                            title: `${columnNameMap["quarterModelAdded"].name} <span class="info-circle" data-toggle="tooltip" title="The quarter this cancer model was confirmed to be "verified tumor"">&#9432;</span>`,
                            data: 'quarterModelAdded'
                        }
                    ],
                    initComplete() {
                        $('[data-toggle="tooltip"]').tooltip();
                        $(`#${tableId}`).DataTable().columns.adjust();
                    }
                })

                $('#searchTable').on('keyup', function () {
                    var table = $(`#${tableId}`).DataTable()
                    table.search(this.value).draw();
                });
            }); // end of promises
        },
        addTblValToltip() {
            const columns = document.getElementsByTagName('td');
        for (let cell of columns) {
            cell.setAttribute('title', cell.innerHTML);
        }
        },
        onColumnToggle(index) {
            var table = $(`#${tableId}`).DataTable()
            var column = table.column(index);
            column.visible(!column.visible());
        },
        toggleCarrot(selection) {
            let selectionDom = document.getElementById(`${selection}`)
            //   console.log("selectionDom", selectionDom)
            if (selectionDom.classList.contains("show")) {
                d3.select(`#${selection}-carrot`).html("&#9660;")
            } else {
                d3.select(`#${selection}-carrot`).html("&#9650;")
            }
        },
        onCheckFilter(selection, property) {
            if (this.filter.selections[property].includes(selection)) {
                removeItem(this.filter.selections[property], selection)
            } else {
                this.filter.selections[property].push(selection)
            }

            let filteredData = filterCheckedSelections(this.verifiedModels, this.filter.selections) // this result should be saved and used for summary stats
            let stringSearch = constructFilterString(filteredData)

            // console.log("unique ID string search", stringSearch)
            // console.log("this.filter.selections", this.filter.selections)

            var table = $(`#${tableId}`).DataTable()
            // IMPORTANT: this line needs to know which number column is CellModelId. Currenlty it is 29
            table.column(0).search(stringSearch.join("|"), true, false, true).draw();
        }
    }
}


function removeItem(array, item) {
    for (var i = 0; i < array.length; i++) {
        if (array[i] === item) { array.splice(i, 1); }
    }
    return array
}

/**
 * Check the function to see if it can be more efficient
 */
function filterCheckedSelections(data, selections) {
    var filtered;
    selections = checkProperties(selections)

    if (Object.getOwnPropertyNames(selections).length === 0) {  // if all attributes are empty, return all data
        filtered = data
    }
    else {
        filtered = []
        data.forEach(function (d) {
            for (var prop in selections) {
                for (var i = 0; i < selections[prop].length; i++) {
                    if (d[prop] == selections[prop][i]) {
                        filtered.push(d)
                    }
                }
            }
        })
    }

    // let unq_objs = [...new Map(filtered.map(o => [o["internalCCLFId"], o])).values()];

    return filtered
}

function constructFilterString(data) {
    return data.map(function (d) {
        return `(?=.*${d.internalCCLFId})`
    })
}
/**
 * 
 * @param {*} obj is selections. 
 * the function returns just the attributes that arent empty 
 * but this isn't necessary. filter function should just account for empty arrays
 */
function checkProperties(obj) {
    var returned = {};
    for (var key in obj) {
        if (obj[key].length !== 0) {
            returned[key] = obj[key]
        }
    }
    return returned;
}


function createFilterOptions(data, properties) {

    if (properties == false) {
        properties = Object.keys(data[0]);
    }
    let filterData = [];
    for (var prop = 0; prop < properties.length; prop++) { // for each property, make a nest of its values. push these nests into parent array 'nestedProperties'

        let nestedProp = nest()
            .key(function (d) { return d[properties[prop]] })
            .entries(data.filter(function (d) { return d[properties[prop]] != "" }))

        nestedProp.forEach(function (d) { // each checkbox option nest
            d.property = properties[prop];
        })
        filterData.push({
            key: properties[prop],
            values: nestedProp,
            total: nestedProp.length
        })
    }
    filterData.forEach(function (d) {
        d.values = d.values.sort(function (a, b) {
            return d3.descending(a.values.length, b.values.length)
        })
        d.values.forEach(function (each) {
            each.total = each.values.length;
        })
    })

    return filterData
}


function createFilterSelections(data, properties) {
    if (properties == false) {
        properties = Object.keys(data[0]);
    }
    let obj = {}
    for (var prop = 0; prop < properties.length; prop++) {
        obj[properties[prop]] = []
    }
    return obj
}




</script>

<style>
table.dataTable tbody td {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1em 0.5em;
}

</style>


<style scoped>
table.dataTable {
    table-layout: fixed; /* Forces table to respect set column widths */
}
/* .tool-page{
    height:90vh;
} */

.container-full-width {
    width: 100vw;
    height: 100vh;
}

/* #filter-panel-wrapper{
    height:80vh;
} */
#filter-panel-wrapper .dropdown-menu-label {
    font-weight: 900;
    color: #EAA02B;
}

:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 0px;
}

a .dt-button {
    padding: 10px !important;
}
</style>
