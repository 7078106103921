<template>
  <div class="container-fluid">
    <div class="row">
      <h2>Team Members</h2>
    </div>
    <div class="team-grid">
      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/William Sellers.jpg"
          />
        </div>
        <h4 class="team-name">William Sellers, MD</h4>
        <h4 class="team-role">Director, Cancer Program</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img class="team-photo" src="../assets/images/team/Moony Tseng.jpg" />
        </div>
        <h4 class="team-name">Yuen-Yi “Moony” Tseng, PhD</h4>
        <h4 class="team-role">Associate Director</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/Meng Horng Lee.jpeg"
          />
        </div>
        <h4 class="team-name">Meng Horng Lee, PhD</h4>
        <h4 class="team-role">Research Scientist II</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img class="team-photo" src="../assets/images/team/Carmen Rios.jpg" />
        </div>
        <h4 class="team-name">Carmen Rios, MS, MBA</h4>
        <h4 class="team-role">Project Manager & Operations Lead</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/Hannah Golding.jpg"
          />
        </div>
        <h4 class="team-name">Hannah Golding, BS</h4>
        <h4 class="team-role">Research Associate I</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/Lindsay Sheft.jpg"
          />
        </div>
        <h4 class="team-name">Lindsay Sheft, BS</h4>
        <h4 class="team-role">Research Associate I</h4>
      </div>

      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/Christopher Jurcisin.jpg"
          />
        </div>
        <h4 class="team-name">Christopher Jurcisin, BS</h4>
        <h4 class="team-role">Research Associate I</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/Gordon_Saksena.jpg"
          />
        </div>
        <h4 class="team-name">Gordon Saksena</h4>
        <h4 class="team-role">Data Scientist Consultant</h4>
      </div>
      <div class="team-member-item">
        <div class="square-circle">
          <img
            class="team-photo"
            src="../assets/images/team/Barbara Van Hare.jpg"
          />
        </div>
        <h4 class="team-name">Barbara Van Hare</h4>
        <h4 class="team-role">Rare Cancer Communications Lead</h4>
      </div>
    </div>

    <br />
    <br />
    <hr />

    <div class="row">
      <h2>Alumni</h2>
    </div>

    <div class="row">
      <div class="col" v-for="(alum, i) in alumni" :key="i">
        <ul>
          <li v-for="a in alum" :key="a">
            {{ a }}
          </li>
        </ul>
      </div>
  </div>
</div>
</template>


<script>
import * as d3 from "d3";

export default {
  data() {
    return {
      alumni: [],
      numCols: 4
    }
  },
  mounted() {
    let inputData = 'data/CCLF_Alumni_20241118.tsv';
    Promise.all([
         d3.tsv(inputData, function(d){
            return {
                alumni: d["Alumni"],
            }
         })        
       ]).then((data) => {
        let dataset = data[0];
        let chunkSize = Math.ceil(dataset.length/this.numCols);
        for (let i = 0; i < dataset.length; i += chunkSize) {
          let chunk = [];
          for (let j = i; j < i + chunkSize && j < dataset.length; j++) {
            chunk.push(dataset[j].alumni);
          }
          this.alumni.push(chunk);
        }
    })
  },
}
</script>


<style scoped>
ul {
  list-style-type: circle;
}

.team-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    5,
    1fr
  ); /* 6 columns in the first and second rows */
  gap: 20px; /* Adjust the gap as needed */
}

.team-member-item {
  text-align: center;
}
.team-name {
  margin: 0px;
  margin-top: 5px
}
.team-role {
  margin: 0px;
  font-weight: 500;
}
/* .team-photo{
    width:100%;
    height:auto;
    border-radius:100%;
    padding:0rem 10% .5rem 10%;
} */

.square-circle img {
  width: 160px; /* Adjust the width and height to your desired square size */
  height: 160px; /* Adjust the width and height to your desired square size */
  overflow: hidden;
  border-radius: 50%; /* This makes the square into a circle */
  object-fit: cover; /* This ensures the image fills the square without stretching */
}

@media screen and (min-width: 888px) and (max-width: 1100px) {
  .team-member-item {
    text-align: center;
    /* padding: 0rem 1rem 1.5rem 0rem; */
  }

  .square-circle img {
    width: 120px;
    height: 120px;
  }
}

@media screen and (min-width: 200px) and (max-width: 887px) {
  .team-member-item {
    margin-bottom: 20px
  }
  .square-circle img {
    width: 250px;
    height: 250px;
  }
  .team-grid {
    grid-template-columns: repeat(1, 1fr); /* Single column for stacking */
  }
}
</style>
