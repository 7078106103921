import Vue from 'vue';
import VueRouter from 'vue-router';

import FrontPage from '@/views/FrontPage';
import TeamPage from '@/views/TeamPage';

import ModelsPage from '@/views/ModelsPage';


import SOPsPage from '@/views/SOPsPage';
import MilestonesPage from '@/views/MilestonesPage';
import PublicationsPage from '@/views/PublicationsPage';
import NewsPage from '@/views/NewsPage';

import HelpPage from '@/views/HelpPage';
// import PartneringPage from '@/views/PartneringPage';
import OurTechPage from '@/views/OurTechPage';
import HumanCancerModelPage from '@/views/HumanCancerModelPage';
import BrainCancerPage from '@/views/BrainCancerDependencyPage';
import RareCancerPage from '@/views/RareCancerPage';
import CancerSymposiumPage from '@/views/CancerSymposiumPage';


Vue.use(VueRouter);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: FrontPage
        },
        {
            path: '/verified-models',
            name: 'ModelsPage',
            component: ModelsPage
        },
        {
            path: '/sops',
            name: 'SOPsPage',
            component: SOPsPage
        },
        {
            path: '/milestones',
            name: 'MilestonesPage',
            component: MilestonesPage
        },
        {
            path: '/publications',
            name: 'PublicationsPage',
            component: PublicationsPage
        },
        {
            path: '/news',
            name: 'NewsPage',
            component: NewsPage
        },
        {
            path: '/rare-cancer',
            name: 'RareCancerPage',
            component: RareCancerPage
        },
        {
            path: '/brain-cancer-dependency-map',
            name: 'BrainCancerDependencyPage',
            component: BrainCancerPage
        },
        {
            path: '/human-cancer-models',
            name: 'HumanCancerModelPage',
            component: HumanCancerModelPage
        },
        {
            path: '/rare-cancer-symposium',
            name: 'CancerSymposiumPage',
            component: CancerSymposiumPage
        },
        {
            path: '/team',
            name: 'TeamPage',
            component: TeamPage
        },
        {
            path: '/contact-us',
            name: 'HelpPage',
            component: HelpPage
        },
        // {
        //     path: '/partnering',
        //     name: 'PartneringPage',
        //     component: PartneringPage
        // }
        {
            path: '/our-technology',
            name: 'OurTechPage',
            component: OurTechPage
        },
        {
            // redirects user to external URL
            path: '/CCLF_Sample_ECA_Form',
            name: 'CCLFSurveyGoogleForm',
            beforeEnter: () => {
                window.location.href = 'https://forms.gle/sGQrf4MFzwdwdJPS9';
            }
        },
        {
            path: '/CCLF1_Sample_Acquisition_CRF',
            redirect: { name: 'CCLFSurveyGoogleForm' }
        },
    ],
    scrollBehavior() {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ x: 0, y: -20 })
          }, 100)
        })
      }
});


router.afterEach((to) => {
    sendAnalyticsPageview(to);
});

const GA_MEASUREMENT_ID = 'G-GH22BC2DJY';

function sendAnalyticsPageview(to) {
    // implemented following Google documentation
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    const pageInfo = {
        page_path: to.path,
        page_title: to.name
    }
    gtag('config', GA_MEASUREMENT_ID, pageInfo);
}




export default router;