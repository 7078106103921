import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App.vue';
import router from './router.js';
import * as d3 from 'd3';


Vue.config.productionTip = false;
Vue.use(BootstrapVue);

new Vue({
  router,
  d3,
  render: h => h(App),
}).$mount('#app')


