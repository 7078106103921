<template>    
    <div id="home-page" class="container-fluid">
       
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8"> 
                <div class="page-section">
                    <h2>Welcome to the Cancer Cell Line Factory!</h2>
                    <p>
                        The goal of the Broad Cancer Cell Line Factory is to make it possible to convert any tumor sample into an organoid, cell line, or spheroid model. We are focused both on scale and on increasing diversity. Our models are shared broadly with the scientific community.
                    </p>
                 
                    <div class="row align-baseline">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <img id="sunburst" src="../assets/images/sunburst/sunburst-20231114.png">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div id="sunburst-label">
                            <p>
                                The sunburst visualization reflects actual composition of genomically verified CCLF patient models. Colors reflect different tumor types and cohorts. 
                                
                            </p>
                            </div>
                        </div>
                    </div>

                    <div class="block-wrapper">
                        <div class="summary-block-wrapper row-center">
                            <div class="summary-block">
                                <h1 class="summary-stat">3,200+</h1>
                                <h4>Acquired samples</h4>
                            </div>
                            <div class="summary-block">
                                <h1 class="summary-stat">526</h1>
                                <h4>Verified models (unique patients);<br>Total 739 models</h4>
                            </div>
                            <div class="summary-block">
                                <h1 class="summary-stat">69</h1>
                                <h4>Cancer types</h4>
                            </div>
                            <div class="summary-block no-border">
                                <h1 class="summary-stat">42%</h1>
                                <h4>Rare/Pediatric</h4>
                            </div>
                        </div>
                    </div>

                    <div class="page-section">
                        <h2>We are committed to five goals</h2>
                        <ul class="bullet-ul">
                            <li>Convert any patient tumor sample into a perturbable cell model</li>
                            <li>Fill cancer cell model collection gaps to cover as many tumor types and genotypes as possible</li>
                            <li>Share new patient models broadly</li>
                            <li>Increase the representation of underrepresented ethnicities in patient models available for cancer researchers</li>
                            <li>Integrate <i>ex vivo</i> functional testing into cancer precision medicine workflows</li>
                        </ul>
                    </div>
                    <div class="page-section">
                        <h3>Use this website to:</h3>
                        <p><b>Browse</b> all <router-link to="/verified-models"> genomically verified cancer cell models</router-link> <br><br></p>
                        <p><b>Download</b> tissue acquisition, cryopreservation, and other model derivation related <router-link to="/sops">SOPs and cell culturing media recipes</router-link> that the CCLF team has optimized<br><br></p>
                        <p><b>Collaborate</b> with the CCLF team in model derivation, perturbation feasibility, dependency validation, and new technology development <span class="small">(Email us at <a href="mailto:cellfactory@broadinstitute.org">cellfactory@broadinstitute.org</a></span>)<br><br></p>
                        <!-- <ul class="no-bullet-ul">
                            <li><b>Browse</b> all <router-link to="/verified-models"> genomically verified cancer cell models</router-link> <span class="small">(Quarterly update, Q32020)</span></li>
                            <li><b>Download</b> tissue acquisition, cryopreservation, and other model derivation related <router-link to="/sops">SOPs and cell culturing media recipes</router-link> that the CCLF team has optimized</li>
                            <li><b>Collaborate</b> with the CCLF team in model derivation, perturbation feasibility, dependency validation, and new technology development <span class="small">(Email us at <a href="mailto:cellfactory@broadinstitute.org">cellfactory@broadinstitute.org</a></span>)</li>
                        </ul> -->
                    </div>
                </div>
            </div>
            

             <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="news-wrapper page-sub-section">
                    <h3 class="news-list-title">In the news <span class="small see-all-link"> | <router-link to="/news">See all</router-link></span></h3>
                    <ul class="no-bullet-ul lg-ul-li news-ul">
                        <li class="news-li" v-for="article in news" :key="article.id" :id="article.id">
                            <h5 class="news-date">{{ article.date }}</h5>
                            <h4 class="news-title"><a :href=article.url target="_blank">{{ article.title }}</a></h4>
                        </li>
                    </ul>
                 </div>
        
                <div class="publication-wrapper page-sub-section">
                    <h3 class="publication-list-title">Recent publications <span class="small see-all-link"> | <router-link to="/publications">See all</router-link></span></h3>
                    <ul class="no-bullet-ul lg-ul-li publication-ul">
                        <li class="publication-li" v-for="publication in publications" :key="publication.id" :id="publication.id">
                            <h5 class="publication-publication"><i>{{ publication.publication }}</i> | {{ publication.date}} </h5>
                            <h4 class="publication-title"><a :href=publication.url target="_blank">{{ publication.title }}</a></h4>
                        </li>
                    </ul>
                </div>

                <div class="related-links-wrapper page-sub-section">
                    <h3>Related Links</h3>
                    <ul class="no-bullet-ul related-links-ul">
                        <li><a class="list-link" href="https://www.atcc.org/search#q=brod&sort=relevancy" target="_blank">ATCC</a></li>
                        <li><a class="list-link" href="https://braincancerproject.org/" target="_blank">Brain Cancer Project</a></li>
                        <li><a class="list-link" href="https://depmap.org/portal/" target="_blank">DepMap portal</a></li>
                        <li><a class="list-link" href="https://portal.gdc.cancer.gov/exploration/" target="_blank">HCMI searchable catalog</a></li>
                        <li><a class="list-link" href="https://pattern.org/" target="_blank">Pattern.org</a></li>
                        <li><a class="list-link" href="https://rarecancer.org/" target="_blank">Rare Cancer Research Foundation</a></li>
                    </ul>  
                 </div>
                 
             </div>
        </div>



        </div>

</template>

<script>
import * as d3 from "d3";
let publicationData = 'data/CCLF_publications_20241023.tsv';
    let newsData = 'data/CCLF_news_2021.10.12.tsv';
export default {
   data() {
        return {
            publications: [],
            news: []
        };
    },
    mounted(){
   
     Promise.all([
         // only use columns that user has chosen..
         d3.tsv(publicationData, function(d, i){
            return {
                authors: d["Authors"],
                title: d["Title"],
                publication: d["Publication"],
                volume: d["Volume"],
                number: d["Number"],
                pages: d["Pages"],
                year: d["Year"],
                date: d["Date"],
                publisher: d["Publisher"],
                url: d["link"],
                doi: d["doi"],
                abstract: d["Abstract"],
                id: i
            }
         }),
          d3.tsv(newsData, function(d, i){
            return {
                authors: d["Authors"],
                title: d["Title"],
                date: d["Date"],
                url: d["link"],
                abstract: d["Abstract"],
                id: i
            }
         })        

       ]).then((data) => {

           data[0].forEach(function(d){
            if (d.number){
                d.number = `(${d.number}):`
            }
            let authors = d.authors.split("; ")
            d.authors = `${authors[0]}, et al.`;

            d.dateFormat = new Date(d.date);
            var formatDate = d3.timeFormat("%m.%d.%Y");
            d.date = formatDate(d.dateFormat);
           })
           console.log(data[0])
           this.publications = data[0].sort(function(a,b){ return d3.descending(a.dateFormat, b.dateFormat)});

           //get first three items in this.publications
           this.publications = this.publications.slice(0,3)

           console.log(this.publications)
            data[1].forEach(function(d){
                d.dateFormat = new Date(d.date);
                var formatDate = d3.timeFormat("%m.%d.%Y");
                d.date = formatDate(d.dateFormat);
            })
            this.news = data[1].sort(function(a,b){ return d3.descending(a.dateFormat, b.dateFormat)}).filter(function(d){ return d.id == 0 })

            // $('.toggle-list-items').on( 'click', function (e) {
            //         e.preventDefault();
            //         if (e.target.innerHTML == "Show all"){
            //             e.innerHTML = "Show less"
            //             e.target.innerHTML = "Show less"
            //             e.target.innerText = "Show less"
            //         } else {
            //             e.innerHTML = "Show all"
            //             e.target.innerHTML = "Show all"
            //             e.target.innerText = "Show all"
            //         }
                    
            //     } );
        });
    }
}


</script>

<style scoped>

.summary-block > h4{
    font-weight:400;
}

#sunburst-label{
    margin-top:2rem;
     vertical-align: baseline;
}
@media screen and (min-width: 300px) and (max-width: 768px) {

}

</style>