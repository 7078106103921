<template>
    <div id="news-page" class="container">  
        <h2>News</h2>
        <div class="news-wrapper">
            <ul class="no-bullet-ul lg-ul-li news-ul">
                <li class="news-li" v-for="article in news" :key="article.id" :id="article.id">
                    <!-- <div class="news-thumbnail">
                        <img src=""/>
                    </div> -->
                    <div class="news-text">
                        <h5 class="news-date">{{ article.date }}</h5>
                        <h3 class="news-title"><a :href=article.url target="_blank">{{ article.title }}</a></h3>
                        <h5 class="news-author">{{ article.authors }}</h5>
                        <p class="news-abstract">{{article.abstract}}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>     
</template>

<script>
import * as d3 from "d3";
export default {
   data() {
        return {
            news: []
        };
    },
    mounted(){

    let inputData = 'data/CCLF_news_2021.10.12.tsv';
     Promise.all([
         // only use columns that user has chosen..
         d3.tsv(inputData, function(d, i){
            return {
                authors: d["Authors"],
                title: d["Title"],
                date: d["Date"],
                url: d["link"],
                abstract: d["Abstract"],
                id: i
            }
         })        
       ]).then((data) => {
           data[0].forEach(function(d){
                d.dateFormat = new Date(d.date);
                var formatDate = d3.timeFormat("%m.%d.%Y");
                d.date = formatDate(d.dateFormat);
            })
            this.news = data[0].sort(function(a,b){ return d3.descending(a.dateFormat, b.dateFormat)})
        });
    }

}


</script>

<style scoped>
#news-page > .news-wrapper ul li{
    margin: 0rem 0rem 1.75em 0em;
    padding: 0em 0rem 1.75em 0em;
}
.news-text, .news-thumbnail{
    display:inline-block;
    vertical-align:top;
}
/* .news-thumbnail{
    width:200px;
    height:200px;
    border:1px solid grey;
    margin:0 2rem 0 0;
}
.news-text{
    width:calc(100% - 240px);

} */

</style>

