<template>
  <b-navbar fixed="top" id="cclf-navbar" toggleable="lg">
    <b-navbar-brand active-class="nav-brand-active" to="/">
       <img class="align-middle"  src="@/assets/images/CCLF_new_logo_transparent_white_text_20231109.png" id="navbar-brand-logo" alt="cclf logo">
       <span class="align-middle"  id="navbar-brand-name">C<span class="light">ancer</span> C<span class="light">ell</span> L<span class="light">ine</span> F<span class="light">actory</span></span>
    </b-navbar-brand>
    
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item active-class="nav-brand-active" to="/">Home</b-nav-item>

        <b-nav-item-dropdown
          :class="getToolsMenuClasses()"
          text="Tools" 
          right>
            <b-dropdown-item to="/verified-models">Verified Models</b-dropdown-item>
            <b-dropdown-item to="/sops">SOPs</b-dropdown-item>
        </b-nav-item-dropdown>

          <b-nav-item class=""  to="/milestones">Milestones</b-nav-item>
          <b-nav-item-dropdown 
          :class="getFlagshipMenuClasses()"
          text="Flagships" 
         
          right>
          <!-- <b-dropdown-item to="/partnering">Partnering</b-dropdown-item> -->
          <b-dropdown-item to="/rare-cancer">Rare Cancer Initiative</b-dropdown-item>
          <b-dropdown-item to="/brain-cancer-dependency-map">Brain Cancer Dependency Map</b-dropdown-item>
          <b-dropdown-item to="/human-cancer-models">Human Cancer Models Initiative</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown 
          :class="getAboutMenuClasses()"
          text="About" 
         
          right>
          <!-- <b-dropdown-item to="/partnering">Partnering</b-dropdown-item> -->
          <b-dropdown-item to="/our-technology">Our Technology</b-dropdown-item>
          <b-dropdown-item to="/team">Team</b-dropdown-item>
          <b-dropdown-item to="/publications">Publications</b-dropdown-item>
          <b-dropdown-item to="/news">News</b-dropdown-item>
        </b-nav-item-dropdown>
        <!-- <b-nav-item class="" to="/contact-us"> 
            <b-nav-form>
                    <PopUp></PopUp>
            </b-nav-form>
        </b-nav-item> -->
        <b-nav-item id="contact-us_toggle-btn" class="" 
          @click="toggleModal"
          > 
            Contact Us
                  <b-modal ref="contact-us_modal" hide-footer title="">
                    <div class="d-block text-center">
                        <iframe class="contact-us_popup_form" src="https://docs.google.com/forms/d/e/1FAIpQLSf6SWeAmjZLD7WHKt1Pm36tX2vjGqQiyXymPpP2dlComG6nKw/viewform?embedded=true" width="640" height="730" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </div>
                  </b-modal>
                    <!-- <PopUp></PopUp> -->
        </b-nav-item>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>


</template>

<script>
// import PopUp from './PopUp.vue'

export default {
  name: 'NavigationBar',
  // components: {
  //   PopUp
  // },
  data() {
    return {
      tools: ['/verified-models', '/sops'],
       about: ['/partnering', '/team', '/publications'],
       flagships: ['/rare-cancer', '/brain-cancer-dependency-map', '/human-cancer-models'],
        flagshipsDict: {
          "rare-cancer": "Rare Cancer Initiative", 
          "brain-cancer-dependency-map": "Brain Cancer Dependency Map",
          "human-cancer-models": "Human Cancer Models Initiative"
        }
    }
  },
  computed: {
    toggleClass() {
      return this.tools.indexOf(this.$route.path) > -1 ? 'router-link-exact-active' : ''
    }
  },
  methods: {
    getToolsMenuClasses(){
      return this.tools.indexOf(this.$route.path) > -1 ? 'router-link-exact-active' : ''
    },
    getAboutMenuClasses(){
      return this.about.indexOf(this.$route.path) > -1 ? 'router-link-exact-active' : ''
    },
    getFlagshipMenuClasses(){
      return this.flagships.indexOf(this.$route.path) > -1 ? 'router-link-exact-active' : ''
    },
    showModal() {
      this.$refs['contact-us_modal'].show()
    },
    hideModal() {
      this.$refs['contact-us_modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['contact-us_modal'].toggle('#contact-us_toggle-btn')
    }
  }
}

//  :class="$route.path === '/verified-models' ? 'router-link-exact-active' : ''"
</script>

<style>
.navbar{
      padding:0rem .5rem 0rem .5rem;
}
#cclf-navbar{
    vertical-align:middle;
    background-color:white;
    background-color:#004480;
    color:white;
    /* border-bottom:1px solid #5597D1; */
    padding:0.5rem 1rem 0.5rem 1rem;
      /* box-shadow: 0 0 10px 0 #5597D1, 0 0 40px 0 rgba(85, 151, 209, .5); */
      /* box-shadow:0 4px 8px 0 rgba(85, 151, 209, .2), 0 6px 20px 0 rgba(85, 151, 209, .19); */
       /* box-shadow:0 1px 8px 0 rgba(85, 151, 209, .2); */
}

.nav-item {
    font-size: 1.1rem;
    line-height:3.5rem;
    font-weight: 800;
    color:white;
    white-space: nowrap;
    margin:0px;
}
  .nav-item li {
    line-height:1.2rem;
    margin:0rem; 
  }

  .nav-link:hover{
    background-color:rgba(85, 151, 209,.05);
  }
  .nav-item li a:hover {
    background-color:rgba(85, 151, 209,.05);
  }
  .dropdown-menu li{
    background-color:#004480;
  }

  .dropdown-menu li a:hover {
      /* background-color:rgba(85, 151, 209,.2); */
       background-color:rgba(85, 151, 209,.09);
      border:none;
  }

  #cclf-navbar .router-link-exact-active, .nav-item-active {
      background-color:rgba(85, 151, 209,.2);
      font-weight:800;
  }
#cclf-navbar .nav-brand-active{
    border:none; 
    background-color:#004480; 
}
#cclf-navbar .nav-brand-active:hover{
 background-color:none;
}
.navbar-brand{
  margin:0px;
}
#navbar-brand-name{
  font-size:2rem;
  line-height:2rem;
  font-weight:800;
  color:white;
  border:none;
}
#navbar-brand-logo{
  height:2.5rem;
  padding-right:.75rem;
}



</style>