<template>
    <div class="sop-page container">
        <div class="page-section">
            <h2>Protocols</h2>
        <!-- <ul class="no-bullet-ul lg-ul-li">
            <li> -->
                <h4 class="sop-title">Cryopreservation of Human Primary Cells From Fresh Tumor Tissue</h4>
                <p>This protocol describes the standard operating procedure for processing fresh tumor tissue and cryopreserving viable cells for future model generation.<br>
                <a href="#" onclick="window.open('sop_pdfs/SOP_CryoQ.pdf', '_blank', 'fullscreen=yes'); return false;">SOP_CryoQ.pdf</a>
               <br><br>
                </p>
            <!-- </li>
            <li> -->
                <!-- <h4 class="sop-title">DatStat Entry Instructions</h4>
                <p>When a sample is shipped to us, we provide these instructions to guide collaborators to fill in the requested clinical information we require for model generation.<br>
                <a href="#" onclick="window.open('sop_pdfs/SOP_DatStat Entry Instructions.pdf', '_blank', 'fullscreen=yes'); return false;">SOP_DatStat Entry Instructions.pdf</a>
                <br><br>
                </p> -->
            <!-- </li>
            <li> -->
                <h4 class="sop-title">Adherent Cell Model Thawing, Propagation, and Cryopreservation</h4>
                <a href="#" onclick="window.open('sop_pdfs/CELL101 Adherent Cell Model Thawing, Propagation, and Cryopreservation (V1.3).pdf', '_blank', 'fullscreen=yes'); return false;">2D_SOP.pdf</a>
                <br><br>
                
            <!-- </li>
            <li> -->
                <h4 class="sop-title">Organoid Cell Model Thawing, Propagation, and Cryopreservation</h4>
                <a href="#" onclick="window.open('sop_pdfs/CELL103 Organoid Cell Model Thawing, Propagation, and Cryopreservation (V1.4).pdf', '_blank', 'fullscreen=yes'); return false;">3D_SOP.pdf</a>
                <br><br>
                
            <!-- </li>
        </ul> -->

        </div>
        <div class="page-section">
            <h2>Media SOPs</h2>
            <div id="dataTable-wrapper">
                <table id="SOPTable" class="display" style="width:100%"></table>
            </div>
        </div>


    </div>
</template>

 <script>
// http://live.datatables.net/xehimatu/1/edit
// https://datatables.net/extensions/searchpanes/examples/customFiltering/customPane.html

import * as d3 from "d3";
import {map} from 'd3-collection';

let tableId = "SOPTable"
let tableDataFile = 'data/CCLF_media_recipes_20231109.csv';


export default {

    data() {
        return {
            data: []
        };
    },
    mounted(){
         this.init()
    },
    methods: {

        init(){

            Promise.all([
                d3.csv(tableDataFile, function(d){
                    return {
                       SOPNumber: d["SOP Number"],
                       SOPName: d["SOP Name"],
                       date: d["Date"],
                       version: d["Version"],
                       fileName: d["PDF"]
                    }
                })

            ]).then((data) => {
 
                    let sopNameToFile = map();
                    
                    data[0].forEach(function(d){
                        sopNameToFile.set(d.SOPName, d.fileName)
                    })
                    console.log(data)
                  
                    $(`#${tableId}`).DataTable({
                        destroy: true,
                        paging: true,
                        fixedHeader: true,
                        pageLength: 50,
                        // scrollY:"600px",
                        scrollX: true,
                        dom: "t",
                        order: [[0, 'asc']], 
                        data: data[0],
                        columns: [
                            {  
                                title: 'SOP Name', 
                                data: 'SOPName',
                                render: function ( d ) {
                                        return `<a href="#" onclick="window.open('sop_pdfs/${ sopNameToFile.get(d) }.pdf', '_blank', 'fullscreen=yes'); return false;">${d}</a>`
                                }
                            },
                            { title: "SOP Number", data: 'SOPNumber'},
                            { title: "Date", data: 'date'},
                            { title: "Version", data: 'version'}
                        ]
                        ,
                        initComplete() {
                            setTimeout(function() {
                                $(`#${tableId}`).DataTable().columns.adjust().draw();
                            }, 0);
                        }
                    })
                }); // end of promises

            }
          
        }
}





</script>

<style scoped>


</style>

